<template>
	<div class="haowu">
		<div class="nav">
			<div class="title">
				和家好物
			</div>
			<div class="tab">
				<div class="item" :class="currentIndex==idx?'current':''" v-for="(nav,idx) in list" :key="idx" @click="onNavClick(idx)">{{nav.name}}</div>
				<div class="border" :style="{marginLeft: (currentIndex * 90 + 25 )+'px'}"></div>
			</div>
		</div>
		<div class="marquee">
			<el-carousel ref="dom"  height="400px" indicator-position="none" :autoplay="false" @change="onchange">
				<el-carousel-item v-for="(item,idx) in list" :key="idx">
					<div class="box">
						<router-link target="_blank" :to="'/sku-'+goods.sku_id" class="goods" v-for="(goods,idx) in item.goodsList" :key="idx">
							<div class="brand" :class="'brand_'+idx">{{goods.brand_name}}</div>
							<el-image class="cover" fit="cover" :src="$img(goods.images[1] || goods.images[0],{size:'mid'})"></el-image>
							<el-image class="img" fit="cover" :src="$img(goods.images[0],{size:'mid'})"></el-image>
							<div class="name">{{goods.goods_name}}</div>
							<div class="price">
								<span class="yen">&yen;</span>{{goods.price}}
								<span class="attr">{{goods.spec_name}}</span>
							</div>
						</router-link>
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
	</div>
</template>
<script>
import {floors} from '@/api/pc.js';
export default{
	name:'haowu',
	data(){
		return{
			list:[],
			currentIndex:0,
			four:[1,2,3,4]
		}
	},
	created(){
		floors({ 
			title:'和家好物'
		}).then( res=>{
			const navs = [
				{
					name:'客厅',
					category_id:10
				},
				{
					name:'卧室',
					category_id:13
				},
				{
					name:'餐厅',
					category_id:27
				},
				{
					name:'儿童房',
					category_id:47
				},
				{
					name:'书房',
					category_id:42
				},
				{
					name:'窗帘',
					category_id:53
				},
				{
					name:'灯饰',
					category_id:3
				}
			];
			var goodsList = this.$util.checkObject( res, ['data',0,'value','goodsList','value','list']  ) || [];
			goodsList.forEach( goods => {
				goods.category_json = this.$util.getGoodsCategoryJson(  goods.category_json );
				goods.images = goods.goods_image.split(',');
				navs.some( nav => {
					if(  goods.category_json.indexOf( nav.category_id ) >= 0 ){
						nav.goodsList = nav.goodsList || [];
						nav.goodsList.push( goods )
						return true;
					}
				} )
			} )
			var list = [];
			navs.forEach( nav  =>{
				if( nav.goodsList && nav.goodsList.length == 4 ){
					list.push( nav );
				}
			} )
			this.list = list;
		} )
	},
	methods:{
		onchange(idx){
			this.currentIndex = idx
		},
		onNavClick(idx){
			this.$refs.dom.setActiveItem(idx);
		}
	}
}
</script>
<style lang="scss" scoped>
.haowu{
	width:$width;
	margin:60px auto 0;
	.nav{
		height:40px;
		line-height: 40px;
		margin-bottom:20px;
		.title{			
			font-size:28px;
			color:#666;
			position:absolute;
		}
		.tab{
			width:450px;
			margin:0 auto;
			font-size:18px;
			color:#999;
			.item{
				display:inline-block;
				width:90px;
				text-align: center;
				cursor: pointer;
				transition: color 0.5s;
				&.current,&:hover{
					color:#333;
				}
			}
			.border{
				position: absolute;
				width:40px;
				height:2px;
				background:#333;
				margin-left:25px;
				transition:margin 0.5s;
			}
		}
	}
	.box{
		display:flex;
		justify-content: space-between;
		.goods{
			width:320px;
			cursor: pointer;
			.img{
				width:320px;
				height:320px;
			}
			.cover{
				width:320px;
				height:320px;
				opacity: 0;
				transition: opacity 0.3s;
				position: absolute;
				z-index: 2;
				transition: opacity .5s;
			}
			.brand{
				position: absolute;
				z-index: 3;
				margin-top:240px;
				height:30px;
				line-height:30px;
				background:#333;
				color:#fff;
				border-top-right-radius: 15px;
				border-bottom-right-radius: 15px;
				font-size:14px;
				font-weight: 700;
				padding:0 15px 0 10px;
				min-width: 60px;
				text-align: center;
				opacity: 0.85;
				&.brand_1{
					background-color: #f5d88f;
				}
				&.brand_2{
					background-color: #5480ac;
				}
				&.brand_3{
					background-color: #9cad7b;
				}
				&.brand_0{
					background-color: #93c7d5;
				}
			}
			.name{
				height:40px;
				line-height: 40px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.price{
				font-size:28px;
				line-height:40px;
				font-weight: 700;
				font-family: Arial, Helvetica, sans-serif;
				.yen{
					font-size:18px;
				}
				.attr{
					background:#eee;
					color:#999;
					font-size:14px;
					line-height:20px;
					font-weight: 500;
					display: inline-block;
					padding:0 3px;
					border-radius: 2px;
					vertical-align: middle;
				}
			}
			&:hover{
				color:#000;
				.cover{
					opacity: 1;
				}
			}
		}
	}
}
</style>