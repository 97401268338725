<template>
	<div>
		<!-- 焦点图 -->
		<div class="banner">
			<el-carousel height="560px" arrow="never" v-loading="loadingAd" :interval="5000" @change="handleChange">
				<el-carousel-item v-for="item in adList" :key="item.adv_id">
					<div :style="'background-image:url('+$img(item.adv_image)+');height:560px;background-size:1920px 560px;background-repeat:no-repeat;background-position:center top'" @click="$router.pushToTab(item.adv_url.url)"></div>
				</el-carousel-item>
			</el-carousel>
		</div>

		<haowu />

		<brand />

		<stylefloor ap_code="NS_PC_INDEX_FLOOR_1" />

		<stylefloor ap_code="NS_PC_INDEX_FLOOR_2" />

		<stylefloor ap_code="NS_PC_INDEX_FLOOR_3" />

		<stylefloor ap_code="NS_PC_INDEX_FLOOR_4" />

		<stylefloor ap_code="NS_PC_INDEX_FLOOR_5" />
		
		<stylefloor ap_code="NS_PC_INDEX_FLOOR_6" />

	</div>
</template>

<script>
import haowu from './components/haowu.vue';
import brand from './components/brand.vue';
import stylefloor from './components/stylefloor.vue';
import index from './_index/index.js';

export default {
	name: 'index',
	components: { haowu,brand,stylefloor },
	mixins: [index]
};
</script>

<style lang="scss" scoped>
.banner{
	min-width:$width;
	max-width:1920px;
	margin:0 auto;
}
@import './_index/index.scss';
</style>

