<template>
	<div class="stylefloor" :style="'background-image:url('+$img(ad.adv_image)+')'">
		<div class="title">
			<div class="name"><strong>{{ad.adv_title}}</strong>风格</div>
			<p>{{ad.desc}}</p>
		</div>
		<div class="goodsbox">
			<router-link :to="'sku-'+item.sku_id" :class="'goods goods_'+idx" v-for="(item,idx) in goods" :key="idx">
				<el-image fit="cover" class="img" :src="$img( item.adv_image || item.sku_image )"></el-image>
				<div :class="'brand brand_'+idx">{{item.brand_name}}</div>
				<div class="info">
					<div class="name">{{item.goods_name}}</div>
					<div class="price">
						<span class="yen">&yen;</span>{{item.price}}
					</div>
				</div>
			</router-link>
		</div>
		<div class="brands">
			<div class="head">{{ad.adv_title}}品牌</div>
			<div class="box">
				<router-link :to="'/list?brand_id='+item.brand_id" :class="'goods goods_'+idx" v-for="(item,idx) in brand_goods" :key="idx">
					<el-image fit="cover" class="img" :src="$img( item.adv_image || item.sku_image )"></el-image>
					<div class="info">
						<el-image class="logo" fit="contain" :src="$img(item.image_url)"></el-image>
						<div class="border"></div>
						<div class="brandname">{{item.brand_name}}</div>
						<div class="brandslogan">{{item.slogan}}</div>
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>
<script>
import { stylefloor } from '@/api/pc.js'
export default {
	name:'stylefloor',
	props:{
		ap_code:{
			type:String
		}
	},
	data(){
		return {
			ad:{},
			goods:[],
			brand_goods:[]
		}
	},
	created(){
		stylefloor( {ap_code:this.ap_code} ).then(res=>{
			this.ad = res.data.ad;
			this.goods = res.data.goods;
			this.brand_goods = res.data.brand_goods;
		})
	}
}
</script>
<style lang="scss" scoped>
.stylefloor{
	min-width: $width;
	max-width: 1920px;
	margin:100px auto 0;
	background-position: center top;
	background-repeat: repeat-x;
	.title{
		padding: 74px 0 65px;
  		text-align: center;
		.name{
			font-size:36px;
			line-height:43px;			
		}
		p{
			margin-top:20px;
			color:#999;
		}
	}
	.goodsbox{
		width:$width;
		margin:0 auto;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		.goods{
			width:433px;
			margin-bottom:20px;
			cursor: pointer;
			.img{
				width:433px;
				height:350px;
				vertical-align: top;
				transition: opacity .5s;
			}
			.brand{
				position: absolute;
				z-index: 3;
				margin-top:-60px;
				height:30px;
				line-height:30px;
				background:#333;
				color:#fff;
				border-top-right-radius: 15px;
				border-bottom-right-radius: 15px;
				font-size:14px;
				font-weight: 700;
				padding:0 15px 0 10px;
				min-width: 60px;
				text-align: center;
				opacity: 0.85;
				&.brand_1{
					background-color: #ff9966;
				}
				&.brand_2{
					background-color: #5480ac;
				}
				&.brand_3{
					background-color: #9cad7b;
				}
				&.brand_4{
					background-color: #f47098;
				}
			}
			.info{
				height:90px;
				color:#333;
				.name{
					height:40px;
					padding-top:10px;
					line-height: 40px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.price{
					font-size:28px;
					line-height:30px;
					font-weight: 700;
					font-family: Arial, Helvetica, sans-serif;
					.yen{
						font-size:18px;
					}
				}
			}
			&.goods_0{
				width:886px;
				.img{
					width:886px;
					height:440px;
				}
				.brand{
					margin-top:-150px;
				}
				.info{
					color:#fff;
					position: absolute;
					z-index: 2;
					margin-top:-90px;
					padding-left:15px;
					font-weight: bold;
				}
			}
			&:hover{
				.img{
					opacity: 0.7;
				}
			}
		}
	}
	.brands{
		width:$width;
		margin:10px auto 0;
		.head{
			font-size:24px;
			line-height: 64px;
			color:#333;
			border-bottom:1px solid #ccc;
			margin-bottom:30px;
		}
		.box{
			display:flex;
			justify-content: space-between;
			.goods{
				width:320px;
				height:360px;
				border-bottom:1px solid #ccc;
				overflow: hidden;
				cursor:pointer;
				.img{
					width:320px;
					height:240px;
					vertical-align: top;
				}
				.info{
					opacity: 0.95;
					height:230px;
					width:318px;
					position: relative;
					border:1px solid #ccc;
					border-bottom:none;
					margin-top:0px;
					background:#fff;
					transition: margin-top .5s;
					padding-top:10px;
					transition: margin .5s;
					.logo{
						margin:0 auto;
						width:200px;
						height:100px;
						display:block;
					}
					.border{
						height:2px;
						width:20px;
						background-color:$base-color;
						margin: 18px auto 0px;
						opacity: 0;
						transition: opacity 0.05s,width .5s;
					}
					.brandname{
						line-height:40px;padding-top:20px;
						font-weight: bold;
						font-size:18px;
						text-align: center;
					}
					.brandslogan{
						text-align: center;
						color:#999;
					}
				}
				&:hover{
					.info{
						margin-top:-120px;
						.border{
							width:80px;
							opacity: 1;
						}
					}
				}
			}
		}
	}
}
</style>
