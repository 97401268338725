<template>
	<div class="brand">
		<div class="title">品牌上新</div>
		<div class="box">
			<router-link target="_blank" :to="'/list?brand_id='+item.brand.brand_id" :style="'background-image:url('+$img( item.adv_image )+')'" :class="'item item_'+idx" v-for="(item,idx) in adv" :key="idx">
				<div class="inbrand">
					<el-image class="logo" fit="contain" :src="$img(item.brand.image_url)"></el-image>
					<div class="info">
						<div class="infobox">
							<div class="name">{{item.brand.brand_name}}</div>
							<div class="border"></div>
							<div class="slogan">{{item.brand.slogan}}</div>
						</div>
					</div>
				</div>
			</router-link>
		</div>
	</div>
</template>
<script>
import {brand} from '@/api/pc.js'
export default {
	name:'brand',
	data(){
		return {
			adv:[]
		}
	},
	created(){
		brand().then( res=>{
			this.adv = res.data
		} );
	}
}
</script>
<style scoped lang="scss">
.brand{
	width:$width;
	height:720px;
	margin:60px auto 0;
	.title{
		height:40px;
		line-height: 40px;
		margin-bottom:20px;
		font-size:28px;
		color:#666;
	}
	.item{
		position:absolute;
		cursor: pointer;
		display: flex;
    	align-items: center;
		background-size: cover;
		background-position: center center;
		&.item_0{
			width:440px;
			height:660px;
		}
		&.item_1{
			margin-left:460px;
			width:580px;
			height:320px;
		}
		&.item_2{
			margin-left:1060px;
			width:280px;
			height:320px;
		}
		&.item_3{
			margin-left:460px;
			margin-top:340px;
			width:280px;
			height:320px;
		}
		&.item_4{
			margin-left:760px;
			margin-top:340px;
			width:580px;
			height:320px;
		}
		.bg{
			width:100%;
			height:100%;
			
		}
		.inbrand{
			width:220px;
			height:120px;
			margin-left: auto;
			margin-right:auto;
			opacity: 0.85;
			background:#fff;
			padding:0;
			transition: padding .8s;
			border-radius: 10px;
			.logo{
				width:160px;
				height:80px;
				margin:20px auto 0;
				display: block;
				transition: opacity .8s;
			}
			.info{
				width:160px;
				height:80px;
				margin:-80px 30px 0;
				text-align: center;
				opacity: 0;
				position: absolute;
				transition: opacity .8s;
				.name{
					line-height:40px;
					font-size:16px;
					font-weight:bold;
				}
				.border{
					height:2px;
					width:2px;
					margin:0 auto;
					background-color:$base-color;
					transition: width .8s;
				}
				.slogan{
					color:#666;
					line-height: 38px;
				}
			}
		}
		&:hover{
			.inbrand{
				padding:10px;
				.info{
					opacity: 1;
					.border{
						width:50px;
					}
				}
				.logo{
					opacity: 0;
				}
			}
		}
	}
}
</style>
