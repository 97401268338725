import http from "../utils/http"

/**
 * 楼层
 * @param {object} params
 */
export function floors(params) {
    return http({
        url: "/api/pc/floors",
        data: params
    })
}

/**
 * 获取热门搜索关键词
 */
export function apiHotSearchWords(params) {
    return http({
        url: "/api/goods/hotSearchWords",
        data: params
    })
}

/**
 * 获取默认搜索关键词
 */
export function apiDefaultSearchWords(params) {
    return http({
        url: "/api/goods/defaultSearchWords",
        data: params
    })
}
/**
 * 获取首页浮层
 */
export function floatLayer(params) {
    return http({
        url: "/api/pc/floatLayer",
        data: params
    })
}

/**
 * 首页 品牌上新
 */
export function brand( params ){
    return http({
        url:'/api/pc/brand',
        data:params
    })
}

/**
 * 首页 风格楼层
 */
export function stylefloor( params ){
    return http({
        url:'/api/pc/stylefloor',
        data:params
    })
}
