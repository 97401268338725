import {
	adList
} from "@/api/website"
import {
	mapGetters
} from "vuex"
import CountDown from "vue2-countdown"

export default {
	name: "index",
	components: {
		CountDown
	},
	data: () => {
		return {
			loadingAd: true,
			loadingFloor: true,
			adList: [],
			adLeftList: [],
			adRightList: [],
			floorList: [],
			floatLayer: {
				is_show: false,
				link: {
					url: ""
				}
			},
			indexFloatLayerNum: 0,
			isSub: false,
			siteId: 0,
			listData: [],
			seckillTimeMachine: {
				currentTime: 0,
				startTime: 0,
				endTime: 0
			},
			seckillText: "距离结束",
			backgroundColor: "", // 顶部banner背景颜色
			// 悬浮搜索
			searchType: "goods",
			searchTypeText: "商品",
			keyword: "",
			defaultSearchWords: "",
			isShow: false
		}
	},
	watch: {
		searchType() {
			this.searchTypeText = this.searchType == "goods" ? "商品" : "店铺"
		}
	},
	created() {
		this.getAdList()
		this.$store.dispatch("site/siteInfo") // 站点信息
		if(this.addonIsExit && this.addonIsExit.seckill == 1){
			this.getTimeList()
		}
	},
	computed: {
		...mapGetters(["defaultHeadImage", "addonIsExit", "defaultGoodsImage", "member", "siteInfo", "cartCount"]),
		optionLeft() {
			return {
				direction: 2,
				limitMoveNum: 2
			}
		}
	},
	methods: {
		getAdList() {
			adList({
					keyword: "NS_PC_INDEX"
				})
				.then(res => {
					console.log(res,'getAdList')
					this.adList = res.data.adv_list
					for (let i = 0; i < this.adList.length; i++) {
						if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
					}
					this.backgroundColor = this.adList[0].background
					this.loadingAd = false
				})
				.catch(err => {
					this.loadingAd = false
				})
		},
		handleChange(curr, pre) {
			this.backgroundColor = this.adList[curr].background
		},
		/**
		 * 广告位大图
		 */
		getBigAdList() {
			adList({
					keyword: "NS_PC_INDEX_MID_LEFT"
				})
				.then(res => {
					this.adLeftList = res.data.adv_list
					for (let i = 0; i < this.adLeftList.length; i++) {
						if (this.adLeftList[i].adv_url) this.adLeftList[i].adv_url = JSON.parse(this.adLeftList[i].adv_url)
					}
					this.loadingAd = false
				})
				.catch(err => {
					this.loadingAd = false
				})
		},
		

		
		/**
		 * 图片加载失败
		 */
		imageError(index) {
			this.listData[index].sku_image = this.defaultGoodsImage
		},

		
		search() {
			this.$router.push({
				path: "/list",
				query: {
					keyword: this.keyword
				}
			})
		}
	}
}
